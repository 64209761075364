* {
  font-family:"文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif
}
.glyphicon {
  font-family:"Glyphicons Halflings","文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif
}

.bg-image {
  background: url(../images/yh2018_bg.jpg) no-repeat top center/cover;
  width: 100%;
  height: 100%;
  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9;
  }
}

body {
  color: #5d5d5d;
  font-size: 16px;
  background: rgba(0, 0, 0, 0);
}
.nolink {
  padding: 14px;
  display: block;
  @media screen and (max-width: 768px) {
    padding: 9px 15px;
  }
}

#block-hybridauth-hybridauth {
  position: absolute;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  right: 10px;
  top: 0;
  z-index: 999;
  opacity: 0.2;
  zoom: 0.7;
  overflow: hidden;
  h2 {
    display: inline-block;
    vertical-align: sub;
  }
  .content {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    opacity: 0.6;
  }
  @media screen and (max-width: 768px) {
    zoom: 1;
    h2 {
      display: none;
    }
  }
}
.hybridauth-widget-wrapper {
  display: inline-block;
}
.not-logged-in #header-top {
  display: none;
}

div.messages, div.messages.status { /* Important messages (status, warning, and error) for the user. See also the declarations in messages.css. */
  margin: 1.5em 0; /* Drupal core uses "6px 0" margin */
  padding: 3px 3px 3px 59px;
  color: #3f3f3f;
  border: none;
  background: #75d675 url(../images/icon-tick.png) no-repeat 23px center;

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}
div.warning, div.messages.warning,
tr.warning { /* Medium priority messages */
  border-color: #e9c75f;
}
div.error, div.messages.error,
tr.error { /* High priority messages. See also the .error declaration below. */
  border-color: #e56464;
}
.error { /* Errors that are separate from div.messages status messages. */
  color: #3f3f3f;
}
div.messages.warning, div.warning {
  background: #e9c75f url(../images/icon-warning.png) no-repeat 23px center;
}
div.messages.error, div.error {
  background: #e56464 url(../images/icon-cross.png) no-repeat 23px center;
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  div.messages.status, div.messages {
    background-image: url(../images/icon-tick-2x.png);
    background-size: 16px 16px;
  }
  div.messages.warning, div.warning {
    background-image: url(../images/icon-warning-2x.png);
    background-size: 16px 16px;
  }
  div.messages.error, div.error {
    background-image: url(../images/icon-cross-2x.png);
    background-size: 16px 16px;
  }
}
.messages-inner {
  padding: 15px 20px;
  background: #fff;
}
div.messages.status, div.status,
.ok {
  color: #3f3f3f;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
input.form-text:focus,
textarea.form-textarea:focus {
  border-color: #2196F3;
}

a {
  word-wrap: break-word;
}

#logo {
  display: inline-block;
  vertical-align: middle;

  img {
    &.header--logo {
      height: 100px;
      border-radius: 15px;
    }
    &.print-only {
      display: none;
    }
  }
}

#site-name {
  display: inline-block;
  vertical-align: middle;

  a {
    color: #4b4961;
    background: rgba(255, 255, 255, 0.8);
    line-height: 1.5;
    border-radius: 15px;
    padding: 0 10px;
    margin: 0;
  }
}

#header {
  padding: 10px 0;
  background: transparent;
  text-align: center;
  &.sticky {
    padding-bottom: 60px;
  }
}
.auto-refresh, .print-only, .mobile-version-site-name {
  display: none!important;
}

#main-navigation {
  background: transparent;
  .navbar-header {
    > .container {
      background: rgba(171, 224, 255, 0.85);
      border-radius: 10px;
    }
    .nav li {
      a {
        letter-spacing: 0.5px;
      }
      &.active-trail, &.active {
        a {
          background: rgba(241, 193, 30, 0.81);
        }
      }
      > a:hover {
        background: rgba(255, 105, 58, 0.66);
      }
      &.dropdown {
        &:hover {
          background: #b38c53;
        }
        &.open {
          > a {
            background: #b38c53;
          }
          .dropdown-menu {
            background: #b38c53;
            border-bottom-color: #795548;
            a {
              border-bottom-color: #795548;
            }
          }
        }
      }
    }
  }
}

option[disabled="disabled"] {
  color: #d5d5d5;
}
.hide {
  display: none;
}
.remove-padding {
  padding: 0;
}
a:hover {
  text-decoration: none;
}

body .navbar-header .navbar-toggle {
  background: #ff2f00;
}

#breadcrumb {
  padding: 5px 0;
  background: rgba(240,245,247,0);
  &-inside {
    > .container {
      background: rgba(240,245,247,0.9);
      border-radius: 5px;
    }
  }
  .breadcrumb {
    margin: 5px 0;
  }
}

#block-jquery-countdown-timer-jquery-countdown-timer {
  padding: 0;

  h2 {
    color: #ff8300;
    text-align: center;
    margin: 0 0 5px;
    font-size: 28px;
  }

  #jquery-countdown-timer-note, .countWeeks, .countDiv0 {
    display: none;
  }

  #jquery-countdown-timer {
    width: 100%;
    height: 43px;
    overflow: hidden;
  }
}

#main-content {
  padding: 0 0 20px;
  background: rgba(240, 245, 247, 0);
  > .container {
    background: rgba(240, 245, 247, 0.8);
    -moz-box-shadow: 0 5px 20px 1px #abd8f2;
    -webkit-box-shadow: 0 5px 20px 1px #abd8f2;
    box-shadow: 0 5px 20px 1px #abd8f2;
    border-radius: 5px;
  }
}

h1.page-title {
  margin: 10px 0;
}

tbody tr td {
  vertical-align: middle;
}
tr.even {
  background-color: #DADADA!important;
  border-bottom: 2px solid #F5F5F5;
  td {
    background-color: #DADADA!important;
    border-bottom: 2px solid #F5F5F5;
  }
}
tr.odd {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
  td {
    background-color: #F5F5F5;
    border-bottom: 2px solid #CCC;
  }
}

/* Score Theme */
.gray-right {
  border-right: #A8A7A7 solid 2px;
}
.gray-bottom-imp {
  border-bottom: #A8A7A7 solid 2px!important;
}
.white-bottom {
  border-bottom: 5px solid #cccccc;
}
.views-row-last .white-bottom-last {
  border-bottom: solid 5px #cccccc;
}
.white-left {
  border-left: 5px solid #cccccc;
}
.white-right {
  border-right: 5px solid #cccccc;
}
.white-top {
  border-top: 5px solid #cccccc;
}
.grays-bottom {
  border-bottom: 5px solid #777777;
}
.views-row-last .grays-bottom-last {
  border-bottom: solid 5px #777777;
}
.grays-left {
  border-left: 5px solid #777777;
}
.grays-right {
  border-right: 5px solid #777777;
}
.grays-top {
  border-top: 5px solid #777777;
}
.purple-top {
  border-top: #7D87F7 solid 5px;
}
.purple-bottom {
  border-bottom: #7D87F7 solid 5px;
}
.views-row-last .purple-bottom-last {
  border-bottom: #7D87F7 solid 5px;
}
.purple-right{
  border-right: #7D87F7 solid 5px;
}
.purple-left {
  border-left: #7D87F7 solid 5px;
}
.blue-bottom {
  border-bottom: 5px solid #10b3e2;
}
.views-row-last .blue-bottom-last {
  border-bottom: solid 5px #10b3e2;
}
.blue-left {
  border-left: 5px solid #10b3e2;
}
.blue-right {
  border-right: 5px solid #10b3e2;
}
.blue-top {
  border-top: 5px solid #10b3e2;
}
.green-top {
  border-top: #45CE92 solid 5px;
}
.green-bottom {
  border-bottom: #45CE92 solid 5px;
}
.views-row-last .green-bottom-last {
  border-bottom: #45CE92 solid 5px;
}
.green-right {
  border-right: #45CE92 solid 5px;
}
.green-left {
  border-left: #45CE92 solid 5px;
}
.orange-top {
  border-top: #F8BA37 solid 5px;
}
.orange-bottom {
  border-bottom: #F8BA37 solid 5px;
}
.views-row-last .orange-bottom-last {
  border-bottom: #F8BA37 solid 5px;
}
.orange-right {
  border-right: #F8BA37 solid 5px;
}
.orange-left {
  border-left: #F8BA37 solid 5px;
}
.pink-top {
  border-top: #FF879F solid 5px;
}
.pink-bottom {
  border-bottom: #FF879F solid 5px;
}
.views-row-last .pink-bottom-last {
  border-bottom: #FF879F solid 5px;
}
.pink-right {
  border-right: #FF879F solid 5px;
}
.pink-left {
  border-left: #FF879F solid 5px;
}
.red-top {
  border-top: #F00 solid 5px;
}
.red-bottom {
  border-bottom: #F00 solid 5px;
}
.views-row-last .red-bottom-last {
  border-bottom: #F00 solid 5px;
}
.red-right {
  border-right: #F00 solid 5px;
}
.red-left {
  border-left: #F00 solid 5px;
}

.path-event {
  .content {
    .field-name-body {
      p {
        font-size: 1.2em;
        letter-spacing: 1px;
        line-height: 1.5;
      }
    }
  }
}

.page-event-result {
  table {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  th {
    background-color: #FFF;
    border-bottom: 3px solid #ccc;
  }
  .view-event-result .view-header h2 {
    font-size: 2em;
    text-align: center;
  }
  #paying-form {
    padding: 0 20px 20px;
    border: #54B4D2 5px solid;
    border-radius: 30px;
    background: rgba(255,255,255,0.4);
  }
  .pay-status {
    padding: 15px;
    min-width: 180px;
    .text {
      font-size: 1.2em;
      font-weight: 700;
      color: white;
      img {
        display: inline;
      }
    }
    p {
      margin: 0!important;
    }
    &.nopay {
      background: #e56464;
      .text {
        img.paying_edit {
          padding-left: 10px;
          cursor: pointer;
        }
        img.paying_at_check {
          cursor: pointer;
          background-color: #C5EAA3;
          padding: 5px;
          border-radius: 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
    &.checking {
      background: #e9c75f;
      img.paying_edit {
        padding-left: 10px;
        cursor: pointer;
      }
      img.paying_check {
        cursor: pointer;
        background-color: #C5EAA3;
        padding: 5px;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        margin-bottom: -2px;
      }
      .payinfo {
        background: rgba(255, 255, 255, 0.8);
      }
    }
    &.payed {
      background: #75d675;
      .payinfo {
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.page-event-signup {
  table {
    width: 100%;
  }
  @media screen and (max-width: 740px) {
    .views-field-php {
      min-width: 150px;
    }
  }
  th {
    background-color: #FFF;
    border-bottom: 3px solid #ccc;
    &.views-field-field-rfid-editable {
      display: none;
    }
    &.views-field-field-final-rfid-editable {
      display: none;
    }
  }
  .view-event-signup .view-header h2, .view-event-final-signup .view-header h2 {
    font-size: 2em;
    text-align: center;
  }
  .signup-status {
    padding: 15px;
    .text {
      position: relative;
      left: -15px;
      font-size: 1.2em;
      font-weight: 700;
      color: white;
      img {
        display: inline;
      }
      input[type="text"] {
        width: auto;
      }
    }
    p {
      margin: 0px;
    }
    &.unsign {
      background: #e56464;
      .text {
        img.signup_edit {
          padding-left: 10px;
          position: absolute;
          top: -3px;
          cursor: pointer;
        }
      }
    }
    &.signed {
      background: #75d675;
    }
  }
}

.path-time {
  tr td:first-child {
    text-align: center;
    border-right: #C7C7C7 solid 5px;
  }
  table {
    font-size: 19px;
    border-color: transparent;
  }
  tr:nth-child(2n) {
    background-color: #dadada !important;
    border-bottom: 2px solid #f5f5f5;
  }
  tr:nth-child(n+1) {
    background-color: #f5f5f5;
    border-bottom: 2px solid #CCC;
  }
  @media screen and (max-width: 767px) {
    table {
      width: 100%!important;
    }
  }
}

.page-qua-list {
  #header {
    display: none;
  }
  #main-navigation {
    background-image: url(/sites/all/themes/bootpress/images/yh2018_logo.png);
    background-repeat: no-repeat;
    background-size: auto 42px;
    background-position: 10px 5px;
  }
  #secondary-menu {
    display: none;
  }
  .breadcrumb, #header-top {
    display: none;
  }
  #page {
    overflow: hidden;
  }
  #page-title {
    margin: 0;
  }
  #content {
    padding: 0 10px;
  }
  input.qua_add_text {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .view-display-id-qua_list, .view-display-id-qua_list_2  {
    table {
      width: 100%;
      margin-top: 0px;
      font-size: 18px;
      font-weight: 700;
      thead {
        font-size: 10px;
        th {
          padding: 5px;
        }
      }
      tbody {
        .views-field-title {
          padding: 0 5px;
          max-width: 100px;
        }
      }
    }
  }
  .view-display-id-qua_list {
    > .view-content, > .view-footer {
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
    > .view-content tbody tr:nth-child(1), > .view-content tbody tr:nth-child(2), > .view-content tbody tr:nth-child(3) {
      font-size: 20px;
      border: #F00 solid 5px;
      td {
        padding: 0px;
        height: 55px;
      }
    }
  }
}

.page-qua-check {
  #header {
    display: none;
  }
  #main-navigation {
    background-image: url(/sites/all/themes/bootpress/images/yh2018_logo.png);
    background-repeat: no-repeat;
    background-size: auto 42px;
    background-position: 10px 5px;
    
    &.sticky {
      position: relative;
    }
  }
  #secondary-menu {
    display: none;
  }
  .breadcrumb, #header-top {
    display: none;
  }
  #page-title {
    margin: 0;
    padding-top: 5px;
  }
  #main {
    padding-bottom: 30px;
  }
  #content {
    padding: 0 20px 120px;
  }
}

.page-qua-edit {
  font-size: 14px;
  #header {
    display: none;
  }
  #main-navigation {
    background-image: url(/sites/all/themes/bootpress/images/yh2018_logo.png);
    background-repeat: no-repeat;
    background-size: auto 42px;
    background-position: 10px 5px;
    
    &.sticky {
      position: relative;
    }
  }
  #secondary-menu {
    display: none;
  }
  .breadcrumb, #header-top {
    display: none;
  }
  #page-title {
    margin: 0;
    padding-top: 5px;
  }
  #main {
    padding-bottom: 30px;
  }
  #content {
    padding: 0 20px 120px;
  }
  .return, .delete {
    padding-left: 10px;
    display: inline-block;
  }
  table {
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    margin: 0px;
    tbody {
      tr {
        &.header {
          td {
            padding: 10px;
            width: 50%;
          }
        }
        &.status {
          td {
            margin: 5px 5%;
            width: 50%;
            padding: 10px;
            div.active {
              border: 5px solid #36c;
            }
          }
        }
        &.number {
          td {
            padding: 0;
            div {
              display: inline-block;
              width: 44%;
              margin: 5px 2px;
              border-radius: 10px;
              padding: 5px;
              text-transform: capitalize;
              cursor: pointer;
              border: 5px solid #F5F5F5;
              &.active {
                border-color: #36c;
              }
              &.button {
                &-v0 {
                  background: #e6e6e6;
                }
                &-v1 {
                  background: #949495;
                }
                &-v2 {
                  background: #ffec47;
                }
                &-v3 {
                  background: #ff6666;
                }
                &-route {
                  &:after {
                    padding: 0 0 0 10px;
                  }
                  &-1, &-3, &-5, &-7 {
                    background: #e6e6e6;
                    &:after {
                      content: "銀色";
                    }
                  }
                  &-2, &-4, &-6, &-8 {
                    background: #e6e6e6;
                    &:after {
                      content: "白色";
                    }
                  }
                  &-9, &-12, &-15, &-18 {
                    background: #949495;
                    color: #FFF;
                    &:after {
                      content: "粉紅色";
                    }
                  }
                  &-10, &-13, &-16, &-19 {
                    background: #949495;
                    color: #FFF;
                    &:after {
                      content: "淺粉紅色";
                    }
                  }
                  &-11, &-14, &-17, &-20 {
                    background: #949495;
                    color: #FFF;
                    &:after {
                      content: "橘色";
                    }
                  }
                  &-21, &-24, &-27, &-30 {
                    background: #ffec47;
                    &:after {
                      content: "棕色";
                    }
                  }
                  &-22, &-25, &-28, &-31 {
                    background: #ffec47;
                    &:after {
                      content: "黃色";
                    }
                  }
                  &-23, &-26, &-29, &-32 {
                    background: #ffec47;
                    &:after {
                      content: "淺棕色";
                    }
                  }
                  &-33, &-35, &-37, &-39 {
                    background: #ff6666;
                    color: #FFF;
                    &:after {
                      content: "綠色";
                    }
                  }
                  &-34, &-36, &-38, &-40 {
                    background: #ff6666;
                    color: #FFF;
                    &:after {
                      content: "亮綠色";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .view-display-id-qua_edit_next_2 {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2000;
    .views-row {
      display: inline-block;
      width: 49%;
      text-align: center;
      padding: 5px;
      background: #ffac33;
      margin: auto;
      color: #FFF;
      position: relative;
      .views-field-counter {
        text-align: left;
        font-size: 2.5em;
        font-weight: 700;
        padding: 0 5px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .views-field-field-no, .views-field-field-category {
        text-align: left;
        font-size: 1.2em;
        padding-left: 25px;
        vertical-align: top;
      }
      .views-field-title {
        display: inline-block;
        width: 80%;
        font-size: 1.6em;
        font-weight: 700;
        line-height: 20px;
        max-height: 40px;
        min-height: 30px;
        vertical-align: bottom;
      }
    }
  }
}

.page-qua-full {
  @media screen and (min-width: 1600px) {
    #main-content {
      > .container {
        width: 1600px;
      }
    }
  }
  .view-display-id-qua_list_full_1 > .view-header > .view {
    display: inline-block;
    width: 24%;
    margin: 0 0.5%;
  }
  .view-display-id-qua_list_full_1 > .view-header > .view table {
    width:100%;
  }
  .views-field-title {
    max-width: 125px;
  }
  @media screen and (max-width: 1024px) {
    .view-display-id-qua_list_full_1 > .view-header > .view {
      display: block;
      width: 100%;
      margin: 0 1%;
    }
  }
}

.page-score {
  #breadcrumb {
    display: none;
  }
}

.path-score-qua-edit, .path-score-final-edit, .path-score-qua-rank, .path-score-final-edit, .path-score-final-rank, .path-score-final-none, .path-score-final-women, .path-score-final-men, .path-score-final-child, .path-score-qua-none, .path-score-qua-women, .path-score-qua-men, .path-score-qua-child, .path-score-qua-none-detail, .path-score-qua-women-detail, .path-score-qua-men-detail, .path-score-qua-child-detail {
  font-size: 1.2em;
  .nav-tabs {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    margin: 0 auto;
    padding: 0;
    max-width: 1200px;
    list-style: none;
    -ms-box-orient: horizontal;
    -ms-box-pack: center;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
      -webkit-justify-content: inherit;
      -moz-justify-content: inherit;
      -ms-justify-content: inherit;
      justify-content: inherit;
    }
    li {
      position: relative;
      z-index: 1;
      display: block;
      margin: 0;
      text-align: center;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      @media screen and (max-width: 600px) {
        -webkit-flex: inherit;
        -moz-flex: inherit;
        -ms-flex: inherit;
        flex: inherit;
      }
      a {
        padding: 0 1.5em 0 0.3em;
        color: #0ba4ff;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        line-height: 2.5;
        font-weight: 700;
        letter-spacing: 1px;
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          outline: 1px solid rgba(0, 0, 0, 0);
          border-radius: 10px 10px 0 0;
          background: #b1ddf7;
          box-shadow: inset 0 -3px 3px rgba(0,0,0,0.05);
          content: '';
          -webkit-transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
          transform: perspective(5px) rotateX(0.93deg) translateZ(-0.8px);
          -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
        &:hover {
          background: inherit;
          color: #fff;
          opacity: .85;
          border-color: transparent;
        }
        &.active {
          color: #19a8fe;
          &:after {
            background: #fff;
            box-shadow: none;
          }
        }
      }
    }
  }
  table {
    width: 100%;
    text-align: center;
    border: none;
  }
  thead {
    border-bottom: 5px solid #CCC;
    th {
      border-bottom: 0;
      background: #FFF;
      vertical-align: middle;
      &.empty {
        background: transparent;
        border: none;
      }
    }
  }
  input[type="text"] {
    width: 35px;
    text-align: center;
    padding: 0;
    min-height: inherit;
  }
  label.option {
    display: none;
  }
  input[type="checkbox"] {
    width: 30px;
    height: 30px;
    min-height: inherit;
  }
  th, td {
    padding: 5px;
    text-align: center!important;
  }
  .views-row-edit-static {
    display: none;
  }
  .views-row-edit-edit {
    label {
      display: none;
    }
    select {
      width: auto;
    }
  }
  .result {
    font-size: 1.3em;
    font-weight: 700;
  }
  .rank {
    font-weight: 700;
  }
  .no input[type="text"] {
    width: 60px;
  }
  .rfid input[type="text"] {
    width: 145px;
  }
  .qua-score, .final-score {
    font-size: 20px;
    padding: 5px 0;
    font-weight: 700;
  }
  .qua-y-, .qua-y-N {
    font-size: 0;
    color: transparent;
  }
  .qua-rank, .rank, .qua-y-Y, td.final-no {
    font-size: 24px;
    font-weight: 700;
    color: inherit;
    input[type="text"] {
      width: 50px;
    }
  }
  .qua-check-1:before{
    content: "✔";
    color: #3c763d;
    padding-right: 3px;
  }
  .status-none {
    color: transparent;
    font-size: 0;
  }
  .status-top {
    font-weight: 700;
    background: #ffbf60!important;
  }
  .qua-status-1:before {
    content: "✔";
    color: #3c763d;
    font-size: 30px;
    line-height: .5;
  }
  .qua-status-0:before {
    content: "✖";
    color: transparent!important;
    font-size: 30px;
    line-height: .5;
  }
  .result-score {
    position: absolute;
    top: -4px;
    left: 1px;
  }
  @media screen and (max-width: 992px) {
    table {
      width: auto;
      position: relative;
      white-space: nowrap;
      display: block;
      overflow-x: auto;
      zoom: 0.8;
    }
  }
}
.path-score-qua-women-detail, .path-score-qua-men-detail, .path-score-qua-child-detail {
  .page-title {
    margin-bottom: 0;
  }
  table {
    zoom: 0.7;
  }
  th, td {
    padding: 5px 1px;
  }
  thead tr th:last-child, tbody tr td:last-child {
    visibility: hidden;
    width: 1px;
  }
  .qua-status-1, .qua-status-2 {
    position: relative;
  }
  .qua-status-1:after, .qua-status-2:after {
    position: absolute;
    content: attr(data-line);
    color: #565656;
    right: 1px;
    bottom: -4px;
    transform: scale(.8);
  }
  .qua-score {
    font-size: 20px;
    padding: 5px 0;
    font-weight: 700;
  }
  td[rowspan="6"] {
    font-size: 24px;
    font-weight: 700;
  }
  td.qua-y-N[rowspan="6"], td.qua-y-[rowspan="6"] {
    font-size: 0;
  }
  .qua-rank, .rank, td.qua-y-Y[rowspan="6"], td.final-no {
    font-size: 24px;
    font-weight: 700;
  }
}
.path-score-final-edit {
  table {
    zoom: 0.8;
  }
  input[type="text"] {
    width: 30px;
  }
}
.view-display-id-qua_calc, .view-display-id-qua_single_view, .view-id-qua_check.view-display-id-page {
  .qua-result {
    .views-field {
      width: 9%;
      min-width: 80px;
      display: inline-block;
      position: relative;
      padding: 20px;
      font-size: 2.5em;
      font-weight: 700;
      margin: 5px;
      border-radius: 10px;
      text-align: center;
      &.qua {
        &-score {
          background: #10b3e2;
          padding: 20px 5px;
          color: #FFF;
          min-width: 120px;
          &:before {
            content: "Score";
            position: absolute;
            top: 0;
            left: 6px;
            font-size: 20px;
          }
        }
        &-v0{
          background: #FFF;
          &:before {
            content: "V0";
            position: absolute;
            top: 0;
            left: 6px;
            font-size: 20px;
          }
        }
        &-v1 {
          background: #999;
          color: #FFF;
          &:before {
            content: "V1";
            position: absolute;
            top: 0;
            left: 6px;
            font-size: 20px;
          }
        }
        &-v2 {
          background: #ffeb3b;
          &:before {
            content: "V2";
            position: absolute;
            top: 0;
            left: 6px;
            font-size: 20px;
          }
        }
        &-v3 {
          background: #ff3d3d;
          color: #FFF;
          &:before {
            content: "V3";
            position: absolute;
            top: 0;
            left: 6px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .qua-color {
    margin: 10px 0;
    .views-field {
      width: 9%;
      min-width: 80px;
      display: inline-block;
      position: relative;
      padding: 20px;
      font-size: 2.5em;
      font-weight: 700;
      margin: 5px;
      border-radius: 10px;
      .result-text {
        text-align: center;
        &.qua-status-1:before {
          content: "✔";
        }
        &.qua-status-0:before {
          content: "✖";
          color: transparent;
        }
      }
      .area-no {
        position: absolute;
        top: 0;
        left: 4px;
        font-size: 0.45em;
        font-weight: 500;
        color: #4a291d;
        text-transform: capitalize;
      }
      .route-no {
        position: absolute;
        bottom: 0;
        right: 4px;
        font-size: 0.6em;
        font-weight: 500;
        color: #4a291d;
      }
    }
    &-v0{
      .views-field {
        background: #FFF;
        &:after {
          content: "V0";
          position: absolute;
          top: -2px;
          right: 2px;
          font-size: 20px;
        }
      }
    }
    &-v1 {
      .views-field {
        background: #999;
        color: #FFF;
        &:after {
          content: "V1";
          position: absolute;
          top: -2px;
          right: 2px;
          font-size: 20px;
        }
      }
    }
    &-v2 {
      .views-field {
        background: #ffeb3b;
        &:after {
          content: "V2";
          position: absolute;
          top: -2px;
          right: 2px;
          font-size: 20px;
        }
      }
    }
    &-v3 {
      .views-field {
        background: #ff3d3d;
        color: #FFF;
        &:after {
          content: "V3";
          position: absolute;
          top: -2px;
          right: 2px;
          font-size: 20px;
        }
      }
    }
  }
}
.view-display-id-final_route_1, .view-display-id-final_route_2, .view-display-id-final_route_3 {
  #breadcrumb {
    display: none;
  }
  table {
    width: 100%;
    text-align: center;
  }
  input[type="text"] {
    width: 80%;
    text-align: center;
    min-height: inherit;
    padding: 0;
  }
  input[type="submit"] {
    display: none;
  }
  input[disabled="disabled"] {
    background: #808080!important;
  }
  input[type="text"][disabled="disabled"] {
    background: #d6d6d6 !important;
  }
  .views-row-edit-static {
    display: none;
  }
  th {
    text-align: center!important;
    padding: 10px;
    background: #FFFFFF;
  }
  td {
    padding: 10px 0;
    font-size: 1.5em;
    &.no {
      font-weight: 700;
    }
  }
  .row3 {
    input[type="text"] {
      width: 15%;
    }
    input[type="button"] {
      padding-right: 3%;
      padding-left: 3%;
      font-size: 1em;
      &.plus {
        padding-left: 6%;
        padding-right: 6%;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .row3 {
      input.top_button, input.zone_button {
        font-size: 0.8em;
      }
    }
  }
  @media screen and (max-width: 360px) {
  }
}

.page-score-qua-edit {
  #main-content > .container {
    min-width: 1900px;
    .path-score-qua-edit {
      zoom: 0.7;
    }
  }
}

.start-list {
  .remove-start {
    display: none;
  }
  .add-start {
    display: inline!important;
  }
  table {
    zoom: 2;
    width: 80%!important;
    margin: auto;
  }
  .temp_text {
    display: none;
  }
  .page-title {
    margin-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  #logo img {
    margin: -10px 0;
    &.header {
      &--logo {
        width: auto;
        height: 50px;
      }
    };
  }

  #site-name a {
    font-size: 28px;
  }

  #main-navigation {
    background: rgba(171, 224, 255, 0.85);
    &.sticky {
      background-image: url(/sites/all/themes/bootpress/images/yh2018_logo.png);
      background-repeat: no-repeat;
      background-size: auto 42px;
      background-position: 10px 5px;

      .mobile-version-site-name {
        display: inline-block !important;
        margin: 6px 0 0 45px;
        padding: 5px;
        border-radius: 11px;
        background-color: rgba(255, 255, 255, 0.6);

        > a {
          color: #4b4961;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    .navbar-header > .container {
      background: inherit;
    }
    .navbar-header .nav li {
      background: #b38c53;
      a {
        border-bottom: 1px solid #795548;
        letter-spacing: 0.5px;
      }
      &.active-trail, &.active {
        a {
          background: #753c28;
        }
      }
      &:hover {
        background: #3e2f29;
      }
    }
  }

  .path-event {
    .content {
      .field-name-body {
        p {
          font-size: 1em;
          letter-spacing: 0.5px;
          line-height: 1.2;
        }
      }
    }
  }
}


/* Start: fix bootstrap nav-ber bug on screen 768px http://stackoverflow.com/a/36289507 */

@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

/* End: fix bootstrap nav-ber bug on screen 768px */

@media print {
  html {
    zoom: 0.59;
  }
  body {
    min-width: 1600px;
    background: none;
  }
  html, body, #navigation, #header, #content, #secondary-menu, #page {
    height: auto;
    max-width: 100%;
    overflow: auto;
  }
  .container {
    width: auto;
  }
  #header {
    padding: 0!important;
    > .container > .row > div {
      overflow: hidden;
    }
  }
  h1.page-title {
    text-align: center;
    font-size: 50px;
    margin-bottom: 10px!important;
  }
  #site-name a {
    font-size: 65px;
  }
  .view-header {
    visibility: hidden;
  }
  #page-title {
    margin-top: 0;
  }
  thead {
    display: table-header-group;
  }
  input[type="button"], .tabs, .breadcrumb, #header-top {
    display: none;
  }
  a[href]:after {
    content: "";
  }
  body.admin-menu.adminimal-menu:before, #boot-press-to-top {
    display: none!important;
  }
  table {
    margin: 0;
  }
  .block, #main {
    padding: 0;
  }
  .qua-check-1:before {
    content: ""!important;
  }
  #logo img {
    margin: 0;
    &.print-only {
      display: block;
    }
    &.header--logo, &.header--title {
      float: left;
    }
    &.header--title {
      margin-top: -20px;
    }
  }
}